//core
import { createSelector } from "@reduxjs/toolkit";

//store
import { RootState } from "../../index";
import { DealsListItemType, ListItemType } from "./index";

interface IDataItem {
  id: number | string,
  label?: string,
  [key: string]: any,
}

// state
const partnersFiltersSelector = (state: RootState) => state.admin.partners.filters;
const partnersFiltersDataSelector = (state: RootState) => state.admin.partners.filters.data;
const partnersFiltersErrorSelector = (state: RootState) => state.admin.partners.filters.error;
const partnersFiltersFetchingSelector = (state: RootState) => state.admin.partners.filters.isFetching;

//selectors
export const selectFilters = createSelector(partnersFiltersSelector, (filters) => filters);
export const selectFiltersData = createSelector(partnersFiltersDataSelector, (data) => {
  if (!!data) {
    return {
      ...data,
      approve_statuses: {
        options: [
          ...data.approve_statuses.options,
          { id: 'blocked', label: 'blocked' },
        ]
      },
    }
  } else {
    return data;
  }
});
export const selectFiltersError = createSelector(partnersFiltersErrorSelector, (error) => error);
export const selectFiltersIsFetching = createSelector(partnersFiltersFetchingSelector, (isFetching) => isFetching);

//List
const partnersListDataSelector = (state: RootState) => state.admin.partners.list.data;
const partnersListErrorSelector = (state: RootState) => state.admin.partners.list.error;
const partnersListFetchingSelector = (state: RootState) => state.admin.partners.list.isFetching;

export const selectListData = createSelector(partnersListDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.map((item: any) => {
      if (item.name === 'username') {
        return { ...item, type: 'shortUsername' };
      }
      if (item.name === 'name') {
        return { ...item, type: 'shortUsername' };
      }
      if (item.name === 'contact_type') {
        return { ...item, type: 'contact' };
      }
      if (item.name === 'traffic_url') {
        return { ...item, type: 'trafficUrl' };
      }
      if (item.name === 'traffic_geo') {
        return { ...item, type: 'countriesArray' };
      }
      if (item.name === 'status') {
        return { ...item, type: 'partnerStatus' };
      }
      if (item.name === 'communication_date') {
        return { ...item, type: 'fulldatetime' };
      }
      if (item.name === 'invitation_token') {
        return { ...item, type: 'token' };
      }
      if (item.name === 'promocode') {
        return { ...item, type: 'shortUsername' };
      }
      if (item.name === 'company') {
        return { ...item, type: 'shortUsername' };
      }
      if (item.name === 'registered_by_manager') {
        return { ...item, type: 'registeredByManager' };
      }

      return item;
    });

    const list = data.list.map((item: any) => {
      return {
        ...item,
        traffic_geo: item.traffic_geo.map((geo: string) => {
          return {
            id: geo,
            label: geo,
          }
        })
      }
    });

    return { ...data, list: list, columns: columns };
  } else {
    return data;
  }
});
export const selectListError = createSelector(partnersListErrorSelector, (error) => error);
export const selectListIsFetching = createSelector(partnersListFetchingSelector, (isFetching) => isFetching);
export const selectListDataColumnsSort = createSelector(partnersListDataSelector, (data) => data?.columns.filter((el) => el.sort === true));

//AcceptManagers
const partnersAcceptManagersDataSelector = (state: RootState) => state.admin.partners.acceptManagers.data;
const partnersAcceptManagersErrorSelector = (state: RootState) => state.admin.partners.acceptManagers.error;
const partnersAcceptManagersFetchingSelector = (state: RootState) => state.admin.partners.acceptManagers.isFetching;

export const selectAcceptManagersData = createSelector(partnersAcceptManagersDataSelector, (data) => {
  if (!!data) {
    const newList: IDataItem[] = data.list.reduce((acc, item: IDataItem) => {
      // @ts-ignore
      acc.push({
        ...item,
        // @ts-ignore
        "label": item.value,
      })

      return acc;
    }, []);

    return { ...data, list: newList };
  } else {
    return data;
  }
});
export const selectAcceptManagersError = createSelector(partnersAcceptManagersErrorSelector, (error) => error);
export const selectAcceptManagersIsFetching = createSelector(partnersAcceptManagersFetchingSelector, (isFetching) => isFetching);


//AcceptManagersAll
const partnersAcceptManagersAllDataSelector = (state: RootState) => state.admin.partners.acceptManagersAll.data;
const partnersAcceptManagersAllErrorSelector = (state: RootState) => state.admin.partners.acceptManagersAll.error;
const partnersAcceptManagersAllFetchingSelector = (state: RootState) => state.admin.partners.acceptManagersAll.isFetching;

export const selectAcceptManagersAllData = createSelector(partnersAcceptManagersAllDataSelector, (data) => {
  if (!!data) {
    const newList: IDataItem[] = data.list.reduce((acc, item: IDataItem) => {
      // @ts-ignore
      acc.push({
        ...item,
        // @ts-ignore
        "label": item.value,
      })

      return acc;
    }, []);

    return { ...data, list: newList };
  } else {
    return data;
  }
});
export const selectAcceptManagersAllError = createSelector(partnersAcceptManagersAllErrorSelector, (error) => error);
export const selectAcceptManagersAllIsFetching = createSelector(partnersAcceptManagersAllFetchingSelector, (isFetching) => isFetching);

//Deals
const partnersDealsDataSelector = (state: RootState) => state.admin.partners.deals.data;
const partnersDealsErrorSelector = (state: RootState) => state.admin.partners.deals.error;
const partnersDealsFetchingSelector = (state: RootState) => state.admin.partners.deals.isFetching;

export const selectDealsData = createSelector(partnersDealsDataSelector, (data) => {
  if (!!data) {
    return {
      ...data,
      list: data.list.reduce((acc: DealsListItemType[], item: DealsListItemType) => {
        acc.push({
          ...item,
          value: `${item.id}-${item.value}`
        });
        return acc;
      }, [])
    };
  } else {
    return data;
  }
});
export const selectDealsError = createSelector(partnersDealsErrorSelector, (error) => error);
export const selectDealsIsFetching = createSelector(partnersDealsFetchingSelector, (isFetching) => isFetching);

//Approve
const partnersApproveDataSelector = (state: RootState) => state.admin.partners.approve.data;
const partnersApproveErrorSelector = (state: RootState) => state.admin.partners.approve.error;
const partnersApproveFetchingSelector = (state: RootState) => state.admin.partners.approve.isFetching;

export const selectApproveData = createSelector(partnersApproveDataSelector, (data) => data);
export const selectApproveError = createSelector(partnersApproveErrorSelector, (error) => error);
export const selectApproveIsFetching = createSelector(partnersApproveFetchingSelector, (isFetching) => isFetching);

//Decline
const partnersDeclineDataSelector = (state: RootState) => state.admin.partners.decline.data;
const partnersDeclineErrorSelector = (state: RootState) => state.admin.partners.decline.error;
const partnersDeclineFetchingSelector = (state: RootState) => state.admin.partners.decline.isFetching;

export const selectDeclineData = createSelector(partnersDeclineDataSelector, (data) => data);
export const selectDeclineError = createSelector(partnersDeclineErrorSelector, (error) => error);
export const selectDeclineIsFetching = createSelector(partnersDeclineFetchingSelector, (isFetching) => isFetching);

//Block
const partnersBlockDataSelector = (state: RootState) => state.admin.partners.block.data;
const partnersBlockErrorSelector = (state: RootState) => state.admin.partners.block.error;
const partnersBlockFetchingSelector = (state: RootState) => state.admin.partners.block.isFetching;

export const selectBlockData = createSelector(partnersBlockDataSelector, (data) => data);
export const selectBlockError = createSelector(partnersBlockErrorSelector, (error) => error);
export const selectBlockIsFetching = createSelector(partnersBlockFetchingSelector, (isFetching) => isFetching);

//Unblock
const partnersUnblockDataSelector = (state: RootState) => state.admin.partners.unblock.data;
const partnersUnblockErrorSelector = (state: RootState) => state.admin.partners.unblock.error;
const partnersUnblockFetchingSelector = (state: RootState) => state.admin.partners.unblock.isFetching;

export const selectUnblockData = createSelector(partnersUnblockDataSelector, (data) => data);
export const selectUnblockError = createSelector(partnersUnblockErrorSelector, (error) => error);
export const selectUnblockIsFetching = createSelector(partnersUnblockFetchingSelector, (isFetching) => isFetching);

//ResendConfirmationEmail
const partnersResendConfirmationDataSelector = (state: RootState) => state.admin.partners.resendConfirmationEmail.data;
const partnersResendConfirmationErrorSelector = (state: RootState) => state.admin.partners.resendConfirmationEmail.error;
const partnersResendConfirmationFetchingSelector = (state: RootState) => state.admin.partners.resendConfirmationEmail.isFetching;

export const selectResendConfirmationData = createSelector(partnersResendConfirmationDataSelector, (data) => data);
export const selectResendConfirmationError = createSelector(partnersResendConfirmationErrorSelector, (error) => error);
export const selectResendConfirmationIsFetching = createSelector(partnersResendConfirmationFetchingSelector, (isFetching) => isFetching);

//Aff List
const fieldsAffiliatesDataSelector = (state: RootState) => state.admin.partners.affiliates.data;
const fieldsAffiliatesErrorSelector = (state: RootState) => state.admin.partners.affiliates.error;
const fieldsAffiliatesFetchingSelector = (state: RootState) => state.admin.partners.affiliates.isFetching;

export const selectAffiliatesData = createSelector(fieldsAffiliatesDataSelector, (data) => {
  if (!!data) {
    return {
      ...data,
      list: data.list.reduce((acc: ListItemType[], item: ListItemType) => {
        acc.push({
          ...item,
          value: `${item.id}-${item.value}`,
        });
        return acc;
      }, [])
    }
  } else {
    return data
  }
});
export const selectAffiliatesError = createSelector(fieldsAffiliatesErrorSelector, (error) => error);
export const selectAffiliatesIsFetching = createSelector(fieldsAffiliatesFetchingSelector, (isFetching) => isFetching);

//Chiefs List
const fieldsChiefsDataSelector = (state: RootState) => state.admin.partners.chiefs.data;
const fieldsChiefsErrorSelector = (state: RootState) => state.admin.partners.chiefs.error;
const fieldsChiefsFetchingSelector = (state: RootState) => state.admin.partners.chiefs.isFetching;

export const selectChiefsData = createSelector(fieldsChiefsDataSelector, (data) => data);
export const selectChiefsError = createSelector(fieldsChiefsErrorSelector, (error) => error);
export const selectChiefsIsFetching = createSelector(fieldsChiefsFetchingSelector, (isFetching) => isFetching);

//Departments List
const fieldsDepartmentsDataSelector = (state: RootState) => state.admin.partners.departments.data;
const fieldsDepartmentsErrorSelector = (state: RootState) => state.admin.partners.departments.error;
const fieldsDepartmentsFetchingSelector = (state: RootState) => state.admin.partners.departments.isFetching;

export const selectDepartmentsData = createSelector(fieldsDepartmentsDataSelector, (data) => data);
export const selectDepartmentsError = createSelector(fieldsDepartmentsErrorSelector, (error) => error);
export const selectDepartmentsIsFetching = createSelector(fieldsDepartmentsFetchingSelector, (isFetching) => isFetching);


//Platforms List
const fieldsPlatformsDataSelector = (state: RootState) => state.admin.partners.platforms.data;
const fieldsPlatformsErrorSelector = (state: RootState) => state.admin.partners.platforms.error;
const fieldsPlatformsFetchingSelector = (state: RootState) => state.admin.partners.platforms.isFetching;

export const selectPlatformsData = createSelector(fieldsPlatformsDataSelector, (data) => data);
export const selectPlatformsError = createSelector(fieldsPlatformsErrorSelector, (error) => error);
export const selectPlatformsIsFetching = createSelector(fieldsPlatformsFetchingSelector, (isFetching) => isFetching);

//Platforms Static List
const fieldsPlatformsStaticDataSelector = (state: RootState) => state.admin.partners.platformsStatic.data;
const fieldsPlatformsStaticErrorSelector = (state: RootState) => state.admin.partners.platformsStatic.error;
const fieldsPlatformsStaticFetchingSelector = (state: RootState) => state.admin.partners.platformsStatic.isFetching;

export const selectPlatformsStaticData = createSelector(fieldsPlatformsStaticDataSelector, (data) => data);
export const selectPlatformsStaticError = createSelector(fieldsPlatformsStaticErrorSelector, (error) => error);
export const selectPlatformsStaticIsFetching = createSelector(fieldsPlatformsStaticFetchingSelector, (isFetching) => isFetching);

//Aff Details
const partnersDetailsDataSelector = (state: RootState) => state.admin.partners.details.data;
const partnersDetailsErrorSelector = (state: RootState) => state.admin.partners.details.error;
const partnersDetailsFetchingSelector = (state: RootState) => state.admin.partners.details.isFetching;

export const selectPartnersDetailsData = createSelector(partnersDetailsDataSelector, (data) => data);
export const selectPartnersDetailsError = createSelector(partnersDetailsErrorSelector, (error) => error);
export const selectPartnersDetailsIsFetching = createSelector(partnersDetailsFetchingSelector, (isFetching) => isFetching);



//CreateUpdate new aff
const partnersCreateAffDataSelector = (state: RootState) => state.admin.partners.createPartner.data;
const partnersCreateAffErrorSelector = (state: RootState) => state.admin.partners.createPartner.error;
const partnersCreateAffFetchingSelector = (state: RootState) => state.admin.partners.createPartner.isFetching;

export const selectPartnersCreateAffData = createSelector(partnersCreateAffDataSelector, (data) => data);
export const selectPartnersCreateAffError = createSelector(partnersCreateAffErrorSelector, (error) => error);
export const selectPartnersCreateAffIsFetching = createSelector(partnersCreateAffFetchingSelector, (isFetching) => isFetching);


//CreateUpdate new aff
const partnersAffDealsListDataSelector = (state: RootState) => state.admin.partners.affDealsList.data;
const partnersAffDealsListErrorSelector = (state: RootState) => state.admin.partners.affDealsList.error;
const partnersAffDealsListFetchingSelector = (state: RootState) => state.admin.partners.affDealsList.isFetching;

const affDealsDataListColumns = [
  {type: "select_deal_row", name: "id", sort: true, match: false},
  {type: "shortString", name: "title", sort: true, match: true},
  {type: "string", name: "type_of_transaction", sort: true, match: true},
  {type: "arrayOfStrings", name: "brands", sort: false, match: false},
  {type: "string", name: "created_by", sort: true, match: false},
];
export const selectAffDealsListData = createSelector(partnersAffDealsListDataSelector, (data) => {
  if (!!data) {
    return {
      ...data,
      columns: affDealsDataListColumns,
    }
  } else {
    return data;
  }
});

export const selectAffDealsDataListColumnsSort = createSelector(partnersAffDealsListDataSelector, (data) => {
  return affDealsDataListColumns.filter((el) => el.sort === true)
});
export const selectAffDealsListError = createSelector(partnersAffDealsListErrorSelector, (error) => error);
export const selectAffDealsListIsFetching = createSelector(partnersAffDealsListFetchingSelector, (isFetching) => isFetching);
