import React from 'react';
import { useTranslation } from "react-i18next";
import { Box, Typography, Chip } from "@mui/material";

//styles
import { useFieldStyles } from "./styles";

import { ISelectedData } from "./index";
import DateTimeRange from '../../../../../../TableData/components/DateTimeRange';

interface IFieldProps {
  data: ISelectedData;
  titlePrefix: string;
  exceptions?: string[];
}

const Field: React.FC<IFieldProps> = ({
  data,
  exceptions = [],
  titlePrefix,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useFieldStyles();

  return (
    <Box className={`${styles.wrapper}`}>
      <Box className={`${styles.block} `}>
        <Box className={styles.header}>
          <Typography className={styles.title} variant="subtitle2">
            {t(`${titlePrefix}${data.name}`)}
          </Typography>
        </Box>
        <Box className={styles.list}>
          {Array.isArray(data.data) ? (
            <>
              {data.data.map((item) => {
                return (
                  <Chip
                    key={item.id}
                    className={styles.chip}
                    label={
                      !!data.prefix
                        ? `${t(`${data.prefix}${item.id}`)}`
                        : !exceptions?.includes(data.name)
                          ? `${
                            (data.name === 'affiliateIds' || data.name === 'departmentIds' || data.name === 'managerIds' || data.name === 'trackerIds' || data.name === 'creativeIds' || data.name === 'brands' || data.name === 'webhookIds')
                              ? `${item.id} - `
                              : ''}${item.value
                          }`
                          : `${item.id}-${item.value}`
                    }
                    variant="outlined"
                    size="small"
                  />
                )
              })}
            </>
          ) : (
            <>
              {
                (data.name === 'registrationFrom'
                  || data.name === 'registrationTo'
                  || data.name === 'firstDepositFrom'
                  || data.name === 'firstDepositTo'
                  || data.name === 'cpaQualificationFrom'
                  || data.name === 'cpaQualificationTo'
                )
                  ? (<>
                      <Chip
                        className={styles.chip}
                        variant="outlined"
                        size="small"
                        label={<DateTimeRange value={data.data} />}
                      />
                    </>)
                  : ('')
              }
            </>
          )}

        </Box>
      </Box>
    </Box>
  );
};

export default Field;
