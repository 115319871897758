//core
import React from 'react';
import {useTranslation} from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {Chip} from "@mui/material";

//theme
import {Theme} from "@mui/material/styles/createTheme";
import {useTheme} from "@mui/styles";


interface IStatus {
  value: boolean;
}

const BlockedStatus = ({value}: IStatus) => {
  const theme: Theme = useTheme();
  const {t} = useTranslation();

  return (
    <>
      {value ? (<>
        <Chip size="small" label={t("common.components.status.blocked")} color="error"/>
      </>) : (<>
        <Chip size="small" label={t("common.components.status.active")} color="primary"
              sx={{backgroundColor: theme.palette.success.main}}
        />
      </>)}
    </>
  );
};

export default React.memo(BlockedStatus, arePropsEqual)
