import {api} from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieSupervision, cookieToken} from "../../constants";

const getTrackers = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.list.replace(
    '{affiliateId}',
    data.affiliateId,
  );

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token,
  }

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getConditions = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.conditions
    .replace(
      '{affiliateId}',
      data.affiliateId,
    );

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createTracker = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.create
    .replace(
      '{affiliateId}',
      data.affiliateId,
    );

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getCurrentTracker = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.currentTracker
    .replace('{affiliateId}', data.affiliateId)
    .replace('{trackerId}', data.trackerId);

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateTrackerDeal = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.updateTrackerCondition
    .replace('{affiliateId}', data.affiliateId)
    .replace('{trackerId}', data.trackerId);

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteHistoryRow = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.deleteHistoryRow.replace('{id}', data.id);

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('DELETE')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getHistoryRow = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.getHistoryRow.replace('{id}', data.id);

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateHistoryRow = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.updateHistoryRow.replace('{id}', data.id);

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackersCount = (data: any) => {
  const endpoint = api.admin.trackers.count.replace('{affiliateId}', data.affiliateId);

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateTracker = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.updateTracker
    .replace('{affiliateId}', data.affiliateId)
    .replace('{trackerId}', data.trackerId);

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackerById = (id: string) => {
  const endpoint = api.admin.trackers.details.replace('{trackerId}', id);

  const url = `${endpoint}`;

  return fetch(
    `${url}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const archiveTracker = (id: string | number) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.archive;

  const url = endpoint.replace('{trackerId}', id.toString());

  const payload = {
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${url}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const unArchiveTracker = (id: string | number) => {
  const authenticity_token = getCookie(cookieToken);


  const endpoint = api.admin.trackers.unarchive;

  const url = endpoint.replace('{trackerId}', id.toString());

  const payload = {
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${url}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const saveTrackerPromocode = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.savePromocode
    .replace('{affiliateId}', data.affiliateId)
    .replace('{trackerId}', data.trackerId);

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackingLinksFilter = () => {
  return fetch(`${api.admin.trackers.trackingLinks.filter}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackerCreativesList = (data: any) => {
  const endpoint = api.admin.trackers.trackingLinks.list.replace('{affiliateId}', data.affId);

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(data.payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackerCreativesMyList = (data: any) => {
  const endpoint = `${api.admin.trackers.trackingLinks.myList.replace('{affiliateId}', data.affId)}`;

  return fetch(endpoint, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getCreateTrackingLink = (data: any) => {
  const endpoint = `${api.admin.trackers.trackingLinks.show_create.replace('{affiliateId}', data.affId)}`;

  return fetch(endpoint, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteTrackingLink = (data: any) => {
  const url = api.admin.trackers.trackingLinks.deleteTrackingLink
    .replace('{affiliateId}', data.affId)
    .replace('{id}', data.id);

  const authenticity_token = getCookie(cookieToken);

  const payload = {
    authenticity_token: authenticity_token,
  };

  return fetch(`${url}`, {...RequestOptions('DELETE', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const saveTrackingLink = (data: any) => {
  const endpoint = `${api.admin.trackers.trackingLinks.saveTrackingLinks.replace('{affiliateId}', data.affId)}`;

  return fetch(endpoint, {...RequestOptions('PUT', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getEditTrackingLink = (data: any) => {
  const url = api.admin.trackers.trackingLinks.editTrackingLink
    .replace('{affiliateId}', data.affId)
    .replace('{id}', data.id);

  return fetch(`${url}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getAdditionalSettings = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.additional_settings.replace(
    '{trackerId}',
    data.trackerId.toString()
  );

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateAdditionalSettings = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.trackers.additional_settings.replace(
    '{trackerId}',
    data.trackerId.toString()
  );

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const blockTracker = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = data.is_blocked ? api.admin.trackers.unblock.replace('{trackerId}', `${data.id}`) : api.admin.trackers.block.replace('{trackerId}', `${data.id}`);

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


export const affTrackersService = {
  getTrackers,
  getConditions,
  createTracker,
  getCurrentTracker,
  updateTrackerDeal,
  deleteHistoryRow,
  getHistoryRow,
  updateHistoryRow,
  getTrackersCount,
  updateTracker,
  getTrackerById,
  archiveTracker,
  unArchiveTracker,
  saveTrackerPromocode,
  getTrackingLinksFilter,
  getTrackerCreativesList,
  getTrackerCreativesMyList,
  getCreateTrackingLink,
  deleteTrackingLink,
  saveTrackingLink,
  getEditTrackingLink,
  getAdditionalSettings,
  updateAdditionalSettings,
  blockTracker,
  /*
  updateTrackerName,
  getDefaultCondition,
  */
};
