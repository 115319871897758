import { useDispatch } from "react-redux";

import {
  setCreateCommunicationStatus,
  setDeleteCommunication,
  getCommunicationHistoryList,
  setUpdateCommunicationStatus,
  setTrafficStatusUpdate,
  getProfileData,
  updateProfileData,
  updateChiefsData,
  clearCommunicationCreateState,
  clearCommunicationDeleteState,
  clearCommunicationUpdateState,
  clearTrafficStatusUpdateState,
  clearProfileState,
  clearUpdateProfileState,
  clearUpdateChiefsState,
  clearCommunicationHistoryState,
  editEmailData,
  clearEditEmailState,
  CommunicationCreatePayloadType,
  CommunicationHistoryPayloadType,
  CommunicationDeletePayloadType,
  TrafficStatusUpdatePayloadType,
  ProfilePayloadType,
  UpdateProfilePayloadType,
  UpdateProfileChiefsPayloadType,
  EditEmailPayloadType,
} from "./index";

export const useAffProfile = () => {
  const dispatch = useDispatch();

  const getCommunicationHistory = (data: CommunicationHistoryPayloadType) => dispatch(getCommunicationHistoryList(data))
  const createCommunicationStatus = (data: CommunicationCreatePayloadType) => dispatch(setCreateCommunicationStatus(data));
  const deleteCommunicationStatus = (data: CommunicationDeletePayloadType) => dispatch(setDeleteCommunication(data));
  const updateCommunicationStatus = (data: CommunicationCreatePayloadType) => dispatch(setUpdateCommunicationStatus(data));
  const updateTrafficStatus = (data: TrafficStatusUpdatePayloadType) => dispatch(setTrafficStatusUpdate(data));
  const getProfile = (data: ProfilePayloadType) => dispatch(getProfileData(data));
  const updateProfile = (data: UpdateProfilePayloadType) => dispatch(updateProfileData(data));
  const updateChiefs = (data: UpdateProfileChiefsPayloadType) => dispatch(updateChiefsData(data));

  const editEmail = (data: EditEmailPayloadType) => dispatch(editEmailData(data));
  const clearEditEmail = () => dispatch(clearEditEmailState());

  const clearCommunicationCreate = () => dispatch(clearCommunicationCreateState());
  const clearCommunicationDelete = () => dispatch(clearCommunicationDeleteState());
  const clearCommunicationUpdate = () => dispatch(clearCommunicationUpdateState());
  const clearTrafficStatusUpdate = () => dispatch(clearTrafficStatusUpdateState());
  const clearProfileData = () => dispatch(clearProfileState());
  const clearUpdateProfileData = () => dispatch(clearUpdateProfileState());
  const clearUpdateChiefsData = () => dispatch(clearUpdateChiefsState());
  const clearCommunicationHistoryData = () => dispatch(clearCommunicationHistoryState());

  return {
    getCommunicationHistory,
    createCommunicationStatus,
    deleteCommunicationStatus,
    updateCommunicationStatus,
    updateTrafficStatus,
    getProfile,
    updateProfile,
    updateChiefs,
    clearCommunicationCreate,
    clearCommunicationDelete,
    clearCommunicationUpdate,
    clearTrafficStatusUpdate,
    clearProfileData,
    clearUpdateProfileData,
    clearUpdateChiefsData,
    clearCommunicationHistoryData,
    editEmail,
    clearEditEmail,
  }
}
