import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik, FormikHelpers, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { Box, Grid, InputLabel, Chip } from "@mui/material";
import Button from "../../../../../UI/Buttons/Button";

//helpers
import { isObjectEmpty } from "../../../../../../helpers/isObjectEmpty";

//hooks
import useDidMountEffect from "../../../../../../customHooks/useDidMountEffect";
import { usePartners } from "../../../../../../store/admin/partners/usePartners";

//selectors
import { selectFiltersData } from "../../../../../../store/admin/partners/selectors";

//validation
import { validationRequiredSelectField } from "../../../../../validation/requiredSelect.validation";

//context
import { TableHistoryContext } from "../CommunicationHistory/TableHistoryContext";
import { MuiSelect } from "../../../../../UI/FormFields/MuiSelect";
import MuiTextField from "../../../../../UI/FormFields/MuiTextField";
import { MuiSingleDateTimePicker } from "../../../../../UI/FormFields/MuiSingleDateTimePicker";
import {
  CommunicationCreatePayloadType,
} from "../../../../../../store/admin/affiliateProfile";
import {validationTextField} from "../../../../../validation/textFieldNotRequired.validation";
import HelperField from "./HelperField";
import {MuiCheckbox} from "../../../../../UI/FormFields/MuiCheckbox";
import {getStatusById} from "./utils";
import {useUser} from "../../../../../../store/common/user/useUser";


interface IDeleteCommunicationProps {
  data: any,
  communicationId?: string | number,
  clearData: any | null;
  onSubmit: (data: CommunicationCreatePayloadType) => void;
  onClear: () => void;
  setToggleModal?: (state: boolean) => void,
  affId?: number | string;
  affPlatformId: any;
}

const FormCommunication: React.FC<IDeleteCommunicationProps> = ({
  data,
  affId,
  communicationId = '',
  clearData,
  onSubmit,
  onClear,
  setToggleModal,
  affPlatformId,
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const formRef: any = useRef();
  const params = useParams();
  const { user } = useUser();

  const context = useContext(TableHistoryContext);

  const { getFilters } = usePartners();

  const filterData = useSelector(selectFiltersData);

  let defaultDate = new Date();
        defaultDate.setSeconds(0);
        // defaultDate.setMinutes(0);

  const [initialValues, setInitialValues] = useState<any>({
    status: data.status.value || '',
    date: data.date.value ? new Date(data.date.value) : defaultDate,
    notes: data.notes.value || '',
  });
  // validation errors translation
  const [errorsMessages, setErrorsMessages] = useState({
    required: t("common.validation.required_field"),
    maxLength: t("common.validation.max_length_255"),
    messengerUsernameRequired: t("common.validation.required_field"),
  });

  useEffect(() => {
    if (!filterData) {
      getFilters();
    }
  }, [filterData]);

  useEffect(() => {
    setErrorsMessages({
      required: t("common.validation.required_field"),
      maxLength: t("common.validation.max_length_255"),
      messengerUsernameRequired: t("common.validation.required_field"),
    })

    if (formRef.current) {
      if (!isObjectEmpty(formRef.current.errors)) {
        setTimeout(() => {
          formRef.current.validateForm();
        }, 50)
      }
    }
  }, [i18n.language, t]);

  useEffect(() => {
    setInitialValues({
      status: data.status.value || '',
      date: data.date.value ? new Date(data.date.value) : defaultDate,
      notes: data.notes.value || '',
      send_email: false,
    })
  }, [data]);

  useDidMountEffect(() => {
    if (!!clearData) {
      onClear();
      context.onGenerateCurrentState();
      handleResetForm();
      if (typeof setToggleModal === 'function') {
        setToggleModal(false);
      }
    }
  }, [clearData]);

  const submitForm = (values: FormikValues, actions: FormikHelpers<any>): void => {
    let date = new Date(values.date);
        date.setSeconds(0);
        // date.setMinutes(0);

    const payload = !!communicationId ? {
      communicationId: communicationId,
      affiliateId: !!affId ? affId : (params.affId || ''),
      data: {
        status: values.status || '',
        date: values.date ? date.toISOString() : null,
        notes: values.notes || '',
        send_email: values.send_email,
      }
    } : {
      affiliateId: !!affId ? affId : (params.affId || ''),
      data: {
        status: values.status || '',
        date: values.date ? date.toISOString() : null,
        notes: values.notes || '',
        send_email: values.send_email,
      }
    }

    onSubmit(payload);

    actions.setSubmitting(false);
  };

  const handleResetForm = useCallback(() => {
    const form = formRef.current;

    if (typeof setToggleModal === 'function') {
      setToggleModal(false);
    }

    form.resetForm();
    form.setSubmitting(false);
    form.setFieldValue('date', new Date());
    setTimeout(() => {
      form.validateForm()
    }, 500);
  }, [formRef]);

  const validateStatus = (value: any) => validationRequiredSelectField(value, errorsMessages);
  const validateNotes = (value: any) => validationTextField(value, 256, errorsMessages);

  return (
    <Box>
      {!!data && !!filterData && <>
        <Formik
          validateOnChange
          validateOnBlur
          validateOnMount
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={ (values, actions) => submitForm(values, actions) }
        >
          {
            props => {
              const { values, handleSubmit, handleChange, isValid, dirty, errors } = props;

              const disableSubmit = isValid ? !dirty : true;

              return (
                <form onSubmit={handleSubmit}>
                  {data.status.is_disable ? (
                    <Box sx={{ mb: 3 }}>
                      <InputLabel sx={{ fontSize: '12px', mb: 1 }}>
                        {t("admin.partners.form.communication_statuses.label")}
                      </InputLabel>
                      <Chip
                        label={t(`common.components.communication_status.${values.status}`)}
                        color="success"
                        size="small"
                      />
                    </Box>
                  ) : (
                    <>
                      <MuiSelect
                        keyValue="label"
                        keyId="id"
                        name="status"
                        label={t("admin.partners.form.communication_statuses.label")}
                        data={filterData.communication_statuses.options[affPlatformId]}
                        value={values.status}
                        disabled={data.status.is_disable}
                        validate={validateStatus}
                        prefix="common.components.communication_status."
                      />
                    </>
                  )}

                  <MuiSingleDateTimePicker
                    id="date"
                    name="date"
                    autoComplete="date"
                    label={t("common.forms.fields.date")}
                    value={values.date}
                    maxDate={new Date()}
                  />

                  {!data.status.is_disable && (
                    <>
                      {getStatusById(filterData.communication_statuses.options[affPlatformId], values.status).send_email && (
                        <Box sx={{ margin: '-8px 0 16px' }}>
                          <MuiCheckbox
                            id="send_email"
                            name="send_email"
                            label={t("admin.partners.profile.communication_history.send_email")}
                            value={values.send_email}
                            onChange={handleChange}
                            smallFont={true}
                          />
                        </Box>
                      )}
                    </>
                  )}


                  <MuiTextField
                    size="small"
                    id="notes"
                    type="text"
                    name="notes"
                    autoComplete="notes"
                    label={t("admin.partners.form.notes.label")}
                    disabled={data.notes.is_disable}
                    value={values.notes}
                    onChange={handleChange}
                    validate={validateNotes}
                    multiline={true}
                  />

                  <HelperField
                    name="helper-field"
                    status={values.status}
                    options={filterData.communication_statuses.options[affPlatformId]}
                  />

                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          gradient
                          fullWidth
                          disableElevation
                          type="submit"
                          variant="contained"
                          disabled={disableSubmit}
                        >{t("common.buttons.save")}</Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          fullWidth
                          disableElevation
                          type="button"
                          variant="outlined"
                          color="secondary"
                          onClick={handleResetForm}
                        >{t("common.buttons.cancel")}</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              )
            }
          }
        </Formik>
      </>}
    </Box>
  );
};

export default FormCommunication;
