//core
import React, { useCallback } from "react";
import arePropsEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import {Box, Button, Typography} from "@mui/material";

//styles
import { useStyles } from "./styles";

//components
import DetailsInfoBlock from "../DetailsInfoBlock";

//types
import {itemColumnsData, itemListData} from "../../../../index";

interface IDetailsProps {
  setToggleModal: (arg: boolean) => void;
  row: itemListData;
  columns: itemColumnsData[];
  detailModalTitle?: string;
  translationColumnsPrefix: string;
  translationTooltipsPrefix?: string;
  columnCurrency?: any;
}


const Details: React.FC<IDetailsProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const {
    setToggleModal,
    row,
    columns,
    detailModalTitle,
    translationColumnsPrefix,
    translationTooltipsPrefix = '',
    columnCurrency = null,
  } = props

  const handleCancel = useCallback(() => {
      setToggleModal(false);
  }, [setToggleModal]);

  return (
    <>
      {!!detailModalTitle && (<Typography className={styles.title} variant="h6" component="div">
        {row[detailModalTitle]}
      </Typography>)}

      {columns.map((column) => (
        <DetailsInfoBlock
          column={column}
          row={row}
          key={column.name}
          translationColumnsPrefix={translationColumnsPrefix}
          translationTooltipsPrefix={translationTooltipsPrefix}
          columnCurrency={columnCurrency}
        />
      ))}

      <Box className={styles.actions}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancel}
        >
          {t("common.buttons.cancel")}
        </Button>
      </Box>
    </>
  );
};

export default React.memo(Details, arePropsEqual);
