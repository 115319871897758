//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";
import {setUser} from "../../common/user";
import { affTrackersService } from "../../../services/admin/affTrackers.service";
import {setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState} from "../../common/ui";

export type trackersListItem = {
  id: number | string;
  tracker_code: number | string;
  name: string;
  assigning_at: string;
  description: string;
  type_of_transaction: string;
}

export type trackersColumnsItem = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
}

export interface ITrackersData {
  list: trackersListItem[];
  columns: trackersColumnsItem[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
  available_trackers: number;
}

export interface IGeoItem {
  id: string;
  label: string;
}

export interface IConditionItem {
  id: number;
  title: string;
  type_of_transaction: string;
}

export interface ILanguageItem {
  id: string;
  label: string;
}
export interface IPreviewData {
  url: string;
  recommended: boolean;
}

export interface trackerCreativesListItem {
  atBeginningShow: string;
  atEndShow: string;
  concept: string;
  createdAt: string;
  createdById: number;
  createdByName: string;
  finalUrl: string;
  geo: IGeoItem[];
  id: number;
  language: ILanguageItem[];
  name: string;
  preview: IPreviewData;
  previewComplete: IPreviewData;
  product: string;
  status: string;
  updatedAt: string;
  updatedById: number;
  updatedByName: string;
  trackingLinkId?: number | string | null;
}

export interface trackerCreativesColumnsItem {
  name: string;
  type: string;
  sort: boolean;
}

export interface ITrackerCreativesData {
  list: trackerCreativesListItem[];
  columns: trackerCreativesColumnsItem[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
  available_trackers: number;
}

export type trackersSliceState = {
  list: {
    data: ITrackersData | null,
    error: any,
    isFetching: boolean,
  },
  archivedList: {
    data: ITrackersData | null,
    error: any,
    isFetching: boolean,
  },
  conditions: {
    data: any | null;
    error: any,
    isFetching: boolean;
  },
  tracker: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  trackingLinksFilter: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  trackerCreativesList: {
    data: ITrackerCreativesData | null,
    error: any,
    isFetching: boolean,
  },
  trackerCreativesMyList: {
    data: ITrackerCreativesData | null,
    error: any,
    isFetching: boolean,
  },
  create: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  editName: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  defaultCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  archiveTracker: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  unArchiveTracker: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },

  currentTracker: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateTrackerDeal: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  deleteHistoryRow: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  editHistoryRow: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateHistoryRow: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  settings: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  count: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateTracker: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  savePromocode: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  createTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  deleteTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  saveTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  editTrackingLink: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  additionalSettings: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateAdditionalSettings: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  blockTracker: {
    data: any | null,
    error: any,
    isFetching: boolean,
  }
}

const initialState: trackersSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  archivedList: {
    data: null,
    error: null,
    isFetching: false,
  },
  conditions: {
    data: null,
    error: null,
    isFetching: false,
  },
  tracker: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackingLinksFilter: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackerCreativesList: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackerCreativesMyList: {
    data: null,
    error: null,
    isFetching: false,
  },
  create: {
    data: null,
    error: null,
    isFetching: false,
  },
  editName: {
    data: null,
    error: null,
    isFetching: false,
  },
  defaultCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  archiveTracker: {
    data: null,
    error: null,
    isFetching: false,
  },
  unArchiveTracker: {
    data: null,
    error: null,
    isFetching: false,
  },
  currentTracker: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateTrackerDeal: {
    data: null,
    error: null,
    isFetching: false,
  },
  deleteHistoryRow: {
    data: null,
    error: null,
    isFetching: false,
  },
  editHistoryRow: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateHistoryRow: {
    data: null,
    error: null,
    isFetching: false,
  },
  settings: {
    data: null,
    error: null,
    isFetching: false,
  },
  count: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateTracker: {
    data: null,
    error: null,
    isFetching: false,
  },
  savePromocode: {
    data: null,
    error: null,
    isFetching: false,
  },
  createTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  deleteTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  saveTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  editTrackingLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  additionalSettings: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateAdditionalSettings: {
    data: null,
    error: null,
    isFetching: false,
  },
  blockTracker: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getTrackersListData: any = createAsyncThunk(
  'affTrackers/getListData',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getTrackers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getArchivedTrackersListData: any = createAsyncThunk(
  'affTrackers/getArchivedListData',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getTrackers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getConditionsData: any = createAsyncThunk(
  'affTrackers/getConditionsData',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getConditions(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getSettingsData: any = createAsyncThunk(
  'affTrackers/getSettingsData',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getConditions(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const createNewTrackerData: any = createAsyncThunk(
  'affTrackers/createNewTracker',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.createTracker(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getCurrentTrackerData: any = createAsyncThunk(
  'affTrackers/getCurrentTrackerData',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getCurrentTracker(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateTrackerDealData: any = createAsyncThunk(
  'affTrackers/updateTrackerDealData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.updateTrackerDeal(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const deleteHistoryRowData: any = createAsyncThunk(
  'affTrackers/deleteHistoryRow',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.deleteHistoryRow(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const getHistoryRowData: any = createAsyncThunk(
  'affTrackers/getHistoryRowData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affTrackersService.getHistoryRow(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updateHistoryRowData: any = createAsyncThunk(
  'affTrackers/updateHistoryRowData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.updateHistoryRow(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getCountData: any = createAsyncThunk(
  'affTrackers/getCountData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getTrackersCount(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateTrackerData: any = createAsyncThunk(
  'affTrackers/updateTrackerData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.updateTracker(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrackerById: any = createAsyncThunk(
  'affTrackers/getTrackerById',
  async (payload: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getTrackerById(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const putArchiveTracker: any = createAsyncThunk(
  'affTrackers/putArchiveTracker',
  async (payload: string | number, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.archiveTracker(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.archived_tracker',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const putUnArchiveTracker: any = createAsyncThunk(
  'affTrackers/putUnArchiveTracker',
  async (payload: string | number, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.unArchiveTracker(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.unarchived_tracker',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const saveTrackerPromocode: any = createAsyncThunk(
  'affTrackers/saveTrackerPromocode',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.saveTrackerPromocode(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrackingLinksFilterData: any = createAsyncThunk(
  'affTrackers/getTrackingLinksFilterData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getTrackingLinksFilter();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrackerCreativesListData: any = createAsyncThunk(
  'affTrackers/getTrackerCreativesListData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affTrackersService.getTrackerCreativesList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getTrackerCreativesMyListData: any = createAsyncThunk(
  'affTrackers/getTrackerCreativesMyListData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affTrackersService.getTrackerCreativesMyList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getCreateTrackingLinkData: any = createAsyncThunk(
  'affTrackers/getCreateTrackingLinkData',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getCreateTrackingLink(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const deleteTrackingLinkData: any = createAsyncThunk(
  'affTrackers/deleteTrackingLinkData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.deleteTrackingLink(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success_deleted_tracking_link',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const saveTrackingLinkData: any = createAsyncThunk(
  'affTrackers/saveTrackingLinkData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.saveTrackingLink(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateTrackingLinkData: any = createAsyncThunk(
  'affTrackers/updateTrackingLinkData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.saveTrackingLink(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getEditTrackingLinkData: any = createAsyncThunk(
  'affTrackers/getEditTrackingLinkData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.getEditTrackingLink(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getAdditionalSettingsData: any = createAsyncThunk(
  'affTrackersService/getAdditionalSettingsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affTrackersService.getAdditionalSettings(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updateAdditionalSettingsData: any = createAsyncThunk(
  'affTrackersService/updateAdditionalSettingsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affTrackersService.updateAdditionalSettings(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const blockTrackerState: any = createAsyncThunk(
  'affTrackers/blockTrackerState',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await affTrackersService.blockTracker(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const trackersSlice = createSlice({
  name: 'affTrackers',
  initialState: initialState,
  reducers: {
    setTrackersData(state, action) {
      state.list.data = action.payload;
    },
    setTrackerNameData(state, action) {
      state.tracker.data.tracker.name = action.payload;
    },
    setTrackerTrafficTypeData(state, action) {
      state.tracker.data.tracker.trafficType = action.payload;
    },
    setTrackerNameStateClear(state) {
      state.editName.error = null;
      state.editName.isFetching = false;
      state.editName.data = null;
    },
    setCreateTrackerData(state, action) {
      state.create.data = action.payload;
    },
    clearArchiveTrackerState(state) {
      state.archiveTracker.error = null;
      state.archiveTracker.isFetching = false;
      state.archiveTracker.data = null;
    },
    clearUnArchiveTrackerState(state) {
      state.unArchiveTracker.error = null;
      state.unArchiveTracker.isFetching = false;
      state.unArchiveTracker.data = null;
    },
    setCreativesListData(state, action) {
      state.trackerCreativesList.data = action.payload;
    },
    setCreativesMyListData(state, action) {
      state.trackerCreativesMyList.data = action.payload;
    },
    clearCreateState(state) {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = null;
    },
    clearConditionsState(state) {
      state.conditions.error = null;
      state.conditions.isFetching = false;
      state.conditions.data = null;
    },
    clearCurrentTrackerState(state) {
      state.currentTracker.error = null;
      state.currentTracker.isFetching = false;
      state.currentTracker.data = null;
    },
    clearUpdateTrackerDealState(state) {
      state.updateTrackerDeal.error = null;
      state.updateTrackerDeal.isFetching = false;
      state.updateTrackerDeal.data = null;
    },
    clearDeleteHistoryRowState(state) {
      state.deleteHistoryRow.data = null;
      state.deleteHistoryRow.error = null;
      state.deleteHistoryRow.isFetching = false;
    },
    clearEditHistoryRowState(state) {
      state.editHistoryRow.data = null;
      state.editHistoryRow.error = null;
      state.editHistoryRow.isFetching = false;
    },
    clearUpdateHistoryRowState(state) {
      state.updateHistoryRow.data = null;
      state.updateHistoryRow.error = null;
      state.updateHistoryRow.isFetching = false;
    },
    clearSettingsState(state) {
      state.settings.data = null;
      state.settings.error = null;
      state.settings.isFetching = false;
    },
    clearCountState(state) {
      state.count.data = null;
      state.count.error = null;
      state.count.isFetching = false;
    },
    clearUpdateTrackerState(state) {
      state.updateTracker.data = null;
      state.updateTracker.error = null;
      state.updateTracker.isFetching = false;
    },
    clearTrackerState(state) {
      state.updateTracker.data = null;
      state.updateTracker.error = null;
      state.updateTracker.isFetching = false;
    },
    clearSavePromocodeState(state) {
      state.savePromocode.data = null;
      state.savePromocode.error = null;
      state.savePromocode.isFetching = false;
    },
    clearTrackerDataState(state) {
      state.tracker.data = null;
      state.tracker.error = null;
      state.tracker.isFetching = false;
    },
    clearDeleteState(state) {
      state.deleteTrackingLink.error = null;
      state.deleteTrackingLink.isFetching = false;
      state.deleteTrackingLink.data = null;
    },
    clearSaveTrackingLinkState(state) {
      state.saveTrackingLink.error = null;
      state.saveTrackingLink.isFetching = false;
      state.saveTrackingLink.data = null;
    },
    clearUpdateTrackingLinkState(state) {
      state.updateTrackingLink.error = null;
      state.updateTrackingLink.isFetching = false;
      state.updateTrackingLink.data = null;
    },
    clearEditTrackingLinkState(state) {
      state.editTrackingLink.error = null;
      state.editTrackingLink.isFetching = false;
      state.editTrackingLink.data = null;
    },
    clearAdditionSettingsState(state) {
      state.additionalSettings.data = null;
      state.additionalSettings.error = null;
      state.additionalSettings.isFetching = false;
    },
    clearBlockTrackerState(state) {
      state.blockTracker.data = null;
      state.blockTracker.error = null;
      state.blockTracker.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<trackersSliceState>>) => {
    builder.addCase(getTrackersListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getTrackersListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getTrackersListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
      state.list.data = null;
    });

    builder.addCase(getArchivedTrackersListData.pending, (state ) => {
      state.archivedList.isFetching = true;
      state.archivedList.error = null;
    });
    builder.addCase(getArchivedTrackersListData.fulfilled, (state , action) => {
      state.archivedList.error = null;
      state.archivedList.isFetching = false;
      state.archivedList.data = action.payload;
    });
    builder.addCase(getArchivedTrackersListData.rejected, (state , action) => {
      state.archivedList.isFetching = false;
      state.archivedList.error = action.payload;
      state.archivedList.data = null;
    });

    builder.addCase(getConditionsData.pending, (state ) => {
      state.conditions.isFetching = true;
      state.conditions.error = null;
    });
    builder.addCase(getConditionsData.fulfilled, (state , action) => {
      state.conditions.error = null;
      state.conditions.isFetching = false;
      state.conditions.data = action.payload;
    });
    builder.addCase(getConditionsData.rejected, (state , action) => {
      state.conditions.isFetching = false;
      state.conditions.error = action.payload;
      state.conditions.data = null;
    });

    builder.addCase(createNewTrackerData.pending, (state ) => {
      state.create.isFetching = true;
      state.create.error = null;
    });
    builder.addCase(createNewTrackerData.fulfilled, (state , action) => {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = action.payload;
    });
    builder.addCase(createNewTrackerData.rejected, (state , action) => {
      state.create.isFetching = false;
      state.create.error = action.payload;
    });

    builder.addCase(getCurrentTrackerData.pending, (state ) => {
      state.currentTracker.isFetching = true;
      state.currentTracker.error = null;
    });
    builder.addCase(getCurrentTrackerData.fulfilled, (state , action) => {
      state.currentTracker.error = null;
      state.currentTracker.isFetching = false;
      state.currentTracker.data = action.payload;
    });
    builder.addCase(getCurrentTrackerData.rejected, (state , action) => {
      state.currentTracker.isFetching = false;
      state.currentTracker.error = action.payload;
    });

    builder.addCase(updateTrackerDealData.pending, (state ) => {
      state.updateTrackerDeal.isFetching = true;
      state.updateTrackerDeal.error = null;
    });
    builder.addCase(updateTrackerDealData.fulfilled, (state , action) => {
      state.updateTrackerDeal.error = null;
      state.updateTrackerDeal.isFetching = false;
      state.updateTrackerDeal.data = action.payload;
    });
    builder.addCase(updateTrackerDealData.rejected, (state , action) => {
      state.updateTrackerDeal.isFetching = false;
      state.updateTrackerDeal.error = action.payload;
    });

    builder.addCase(deleteHistoryRowData.pending, (state ) => {
      state.deleteHistoryRow.isFetching = true;
      state.deleteHistoryRow.error = null;
    });
    builder.addCase(deleteHistoryRowData.fulfilled, (state , action) => {
      state.deleteHistoryRow.error = null;
      state.deleteHistoryRow.isFetching = false;
      state.deleteHistoryRow.data = action.payload;
    });
    builder.addCase(deleteHistoryRowData.rejected, (state , action) => {
      state.deleteHistoryRow.isFetching = false;
      state.deleteHistoryRow.error = action.payload;
    });

    builder.addCase(getHistoryRowData.pending, (state ) => {
      state.editHistoryRow.isFetching = true;
      state.editHistoryRow.error = null;
    });
    builder.addCase(getHistoryRowData.fulfilled, (state , action) => {
      state.editHistoryRow.error = null;
      state.editHistoryRow.isFetching = false;
      state.editHistoryRow.data = action.payload;
    });
    builder.addCase(getHistoryRowData.rejected, (state , action) => {
      state.editHistoryRow.isFetching = false;
      state.editHistoryRow.error = action.payload;
    });

    builder.addCase(updateHistoryRowData.pending, (state ) => {
      state.updateHistoryRow.isFetching = true;
      state.updateHistoryRow.error = null;
    });
    builder.addCase(updateHistoryRowData.fulfilled, (state , action) => {
      state.updateHistoryRow.error = null;
      state.updateHistoryRow.isFetching = false;
      state.updateHistoryRow.data = action.payload;
    });
    builder.addCase(updateHistoryRowData.rejected, (state , action) => {
      state.updateHistoryRow.isFetching = false;
      state.updateHistoryRow.error = action.payload;
    });

    builder.addCase(getSettingsData.pending, (state ) => {
      state.settings.isFetching = true;
      state.settings.error = null;
    });
    builder.addCase(getSettingsData.fulfilled, (state , action) => {
      state.settings.error = null;
      state.settings.isFetching = false;
      state.settings.data = action.payload;
    });
    builder.addCase(getSettingsData.rejected, (state , action) => {
      state.settings.isFetching = false;
      state.settings.error = action.payload;
    });

    builder.addCase(getCountData.pending, (state ) => {
      state.count.isFetching = true;
      state.count.error = null;
    });
    builder.addCase(getCountData.fulfilled, (state , action) => {
      state.count.error = null;
      state.count.isFetching = false;
      state.count.data = action.payload;
    });
    builder.addCase(getCountData.rejected, (state , action) => {
      state.count.isFetching = false;
      state.count.error = action.payload;
    });

    builder.addCase(updateTrackerData.pending, (state ) => {
      state.updateTracker.isFetching = true;
      state.updateTracker.error = null;
    });
    builder.addCase(updateTrackerData.fulfilled, (state , action) => {
      state.updateTracker.error = null;
      state.updateTracker.isFetching = false;
      state.updateTracker.data = action.payload;
    });
    builder.addCase(updateTrackerData.rejected, (state , action) => {
      state.updateTracker.isFetching = false;
      state.updateTracker.error = action.payload;
    });

    builder.addCase(getTrackerById.pending, (state ) => {
      state.tracker.isFetching = true;
      state.tracker.error = null;
    });
    builder.addCase(getTrackerById.fulfilled, (state , action) => {
      state.tracker.error = null;
      state.tracker.isFetching = false;
      state.tracker.data = action.payload;
    });
    builder.addCase(getTrackerById.rejected, (state , action) => {
      state.tracker.isFetching = false;
      state.tracker.error = action.payload;
    });

    builder.addCase(putArchiveTracker.pending, (state ) => {
      state.archiveTracker.isFetching = true;
      state.archiveTracker.error = null;
    });
    builder.addCase(putArchiveTracker.fulfilled, (state , action) => {
      state.archiveTracker.error = null;
      state.archiveTracker.isFetching = false;
      state.archiveTracker.data = action.payload;
    });
    builder.addCase(putArchiveTracker.rejected, (state , action) => {
      state.archiveTracker.isFetching = false;
      state.archiveTracker.error = action.payload;
    });

    builder.addCase(putUnArchiveTracker.pending, (state ) => {
      state.unArchiveTracker.isFetching = true;
      state.unArchiveTracker.error = null;
    });
    builder.addCase(putUnArchiveTracker.fulfilled, (state , action) => {
      state.unArchiveTracker.error = null;
      state.unArchiveTracker.isFetching = false;
      state.unArchiveTracker.data = action.payload;
    });
    builder.addCase(putUnArchiveTracker.rejected, (state , action) => {
      state.unArchiveTracker.isFetching = false;
      state.unArchiveTracker.error = action.payload;
    });

    builder.addCase(saveTrackerPromocode.pending, (state ) => {
      state.savePromocode.isFetching = true;
      state.savePromocode.error = null;
    });
    builder.addCase(saveTrackerPromocode.fulfilled, (state , action) => {
      state.savePromocode.error = null;
      state.savePromocode.isFetching = false;
      state.savePromocode.data = action.payload;
    });
    builder.addCase(saveTrackerPromocode.rejected, (state , action) => {
      state.savePromocode.isFetching = false;
      state.savePromocode.error = action.payload;
    });

    builder.addCase(getTrackingLinksFilterData.pending, (state ) => {
      state.trackingLinksFilter.isFetching = true;
      state.trackingLinksFilter.error = null;
    });
    builder.addCase(getTrackingLinksFilterData.fulfilled, (state , action) => {
      state.trackingLinksFilter.error = null;
      state.trackingLinksFilter.isFetching = false;
      state.trackingLinksFilter.data = action.payload;
    });
    builder.addCase(getTrackingLinksFilterData.rejected, (state , action) => {
      state.trackingLinksFilter.isFetching = false;
      state.trackingLinksFilter.error = action.payload;
    });

    builder.addCase(getTrackerCreativesListData.pending, (state ) => {
      state.trackerCreativesList.isFetching = true;
      state.trackerCreativesList.error = null;
    });
    builder.addCase(getTrackerCreativesListData.fulfilled, (state , action) => {
      state.trackerCreativesList.error = null;
      state.trackerCreativesList.isFetching = false;
      state.trackerCreativesList.data = action.payload;
    });
    builder.addCase(getTrackerCreativesListData.rejected, (state , action) => {
      state.trackerCreativesList.isFetching = false;
      state.trackerCreativesList.error = action.payload;
    });

    builder.addCase(getTrackerCreativesMyListData.pending, (state ) => {
      state.trackerCreativesMyList.isFetching = true;
      state.trackerCreativesMyList.error = null;
    });
    builder.addCase(getTrackerCreativesMyListData.fulfilled, (state , action) => {
      state.trackerCreativesMyList.error = null;
      state.trackerCreativesMyList.isFetching = false;
      state.trackerCreativesMyList.data = action.payload;
    });
    builder.addCase(getTrackerCreativesMyListData.rejected, (state , action) => {
      state.trackerCreativesMyList.isFetching = false;
      state.trackerCreativesMyList.error = action.payload;
    });

    builder.addCase(getCreateTrackingLinkData.pending, (state ) => {
      state.createTrackingLink.isFetching = true;
      state.createTrackingLink.error = null;
    });
    builder.addCase(getCreateTrackingLinkData.fulfilled, (state , action) => {
      state.createTrackingLink.error = null;
      state.createTrackingLink.isFetching = false;
      state.createTrackingLink.data = action.payload;
    });
    builder.addCase(getCreateTrackingLinkData.rejected, (state , action) => {
      state.createTrackingLink.isFetching = false;
      state.createTrackingLink.error = action.payload;
    });

    builder.addCase(deleteTrackingLinkData.pending, (state ) => {
      state.deleteTrackingLink.isFetching = true;
      state.deleteTrackingLink.error = null;
    });
    builder.addCase(deleteTrackingLinkData.fulfilled, (state , action) => {
      state.deleteTrackingLink.error = null;
      state.deleteTrackingLink.isFetching = false;
      state.deleteTrackingLink.data = action.payload;
    });
    builder.addCase(deleteTrackingLinkData.rejected, (state , action) => {
      state.deleteTrackingLink.isFetching = false;
      state.deleteTrackingLink.data = null;
      state.deleteTrackingLink.error = action.payload;
    });

    builder.addCase(saveTrackingLinkData.pending, (state ) => {
      state.saveTrackingLink.isFetching = true;
      state.saveTrackingLink.error = null;
    });
    builder.addCase(saveTrackingLinkData.fulfilled, (state , action) => {
      state.saveTrackingLink.error = null;
      state.saveTrackingLink.isFetching = false;
      state.saveTrackingLink.data = action.payload;
    });
    builder.addCase(saveTrackingLinkData.rejected, (state , action) => {
      state.saveTrackingLink.isFetching = false;
      state.saveTrackingLink.error = action.payload;
    });

    builder.addCase(updateTrackingLinkData.pending, (state ) => {
      state.updateTrackingLink.isFetching = true;
      state.updateTrackingLink.error = null;
    });
    builder.addCase(updateTrackingLinkData.fulfilled, (state , action) => {
      state.updateTrackingLink.error = null;
      state.updateTrackingLink.isFetching = false;
      state.updateTrackingLink.data = action.payload;
    });
    builder.addCase(updateTrackingLinkData.rejected, (state , action) => {
      state.updateTrackingLink.isFetching = false;
      state.updateTrackingLink.error = action.payload;
    });

    builder.addCase(getEditTrackingLinkData.pending, (state ) => {
      state.editTrackingLink.isFetching = true;
      state.editTrackingLink.error = null;
    });
    builder.addCase(getEditTrackingLinkData.fulfilled, (state , action) => {
      state.editTrackingLink.error = null;
      state.editTrackingLink.isFetching = false;
      state.editTrackingLink.data = action.payload;
    });
    builder.addCase(getEditTrackingLinkData.rejected, (state , action) => {
      state.editTrackingLink.isFetching = false;
      state.editTrackingLink.error = action.payload;
    });

    builder.addCase(getAdditionalSettingsData.pending, (state ) => {
      state.additionalSettings.isFetching = true;
      state.additionalSettings.error = null;
    });
    builder.addCase(getAdditionalSettingsData.fulfilled, (state , action) => {
      state.additionalSettings.error = null;
      state.additionalSettings.isFetching = false;
      state.additionalSettings.data = action.payload;
    });
    builder.addCase(getAdditionalSettingsData.rejected, (state , action) => {
      state.additionalSettings.isFetching = false;
      state.additionalSettings.error = action.payload;
    });

    builder.addCase(updateAdditionalSettingsData.pending, (state ) => {
      state.updateAdditionalSettings.isFetching = true;
      state.updateAdditionalSettings.error = null;
    });
    builder.addCase(updateAdditionalSettingsData.fulfilled, (state , action) => {
      state.updateAdditionalSettings.error = null;
      state.updateAdditionalSettings.isFetching = false;
      state.updateAdditionalSettings.data = action.payload;
    });
    builder.addCase(updateAdditionalSettingsData.rejected, (state , action) => {
      state.updateAdditionalSettings.isFetching = false;
      state.updateAdditionalSettings.error = action.payload;
    });

    builder.addCase(blockTrackerState.pending, (state ) => {
      state.blockTracker.isFetching = true;
      state.blockTracker.error = null;
    });
    builder.addCase(blockTrackerState.fulfilled, (state , action) => {
      state.blockTracker.error = null;
      state.blockTracker.isFetching = false;
      state.blockTracker.data = action.payload;
    });
    builder.addCase(blockTrackerState.rejected, (state , action) => {
      state.blockTracker.isFetching = false;
      state.blockTracker.error = action.payload;
    });
  }
});

export const {
  setTrackersData,
  setTrackerNameStateClear,
  setTrackerNameData,
  setCreateTrackerData,
  clearArchiveTrackerState,
  clearUnArchiveTrackerState,
  setCreativesListData,
  setCreativesMyListData,
  clearCreateState,
  clearConditionsState,
  clearCurrentTrackerState,
  clearUpdateTrackerDealState,
  clearDeleteHistoryRowState,
  clearEditHistoryRowState,
  clearUpdateHistoryRowState,
  clearSettingsState,
  clearCountState,
  clearUpdateTrackerState,
  clearTrackerState,
  clearSavePromocodeState,
  setTrackerTrafficTypeData,
  clearTrackerDataState,
  clearDeleteState,
  clearSaveTrackingLinkState,
  clearUpdateTrackingLinkState,
  clearEditTrackingLinkState,
  clearAdditionSettingsState,
  clearBlockTrackerState,
} = trackersSlice.actions;


export default trackersSlice.reducer;
