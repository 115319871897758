import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { endOfDay, startOfDay } from "date-fns";
import arePropsEqual from "react-fast-compare";
import {
  Box,
  SelectChangeEvent,
  useMediaQuery,
  TextField,
} from "@mui/material";
import Button from "../../../../../../../UI/Buttons/Button";

//styles
import { useStyles } from "./styles";

//utils
import { getDataIds, getGroupByOptions, getPeriod, PeriodTypes } from "../../../../../utils";

//selectors
import {
  selectDealsData,
  selectOptionsData,
  selectPlatformsData
} from "../../../../../../../../store/admin/reports/summary_report_v2/selectors";

//hooks
import { useSummaryReport } from "../../../../../../../../store/admin/reports/summary_report_v2/useSummaryReport";
import { usePermissions } from "../../../../../../../../store/common/configuration/usePermissions";
import { useRegistration } from "../../../../../../../../store/common/registration/useRegistration";

//context
import { GlobalContext } from "../../../../../../../../context/GlobalContext";

//constants
import { summaryPeriodOptions } from "../../../../../constant";
import { favoriteFilterKeys, reportStorageProps } from "../../../../constants";

//validation
import { validationTextField as validationTextFieldMax } from "../../../../../../../validation/textFieldMax.validation";

//components
import CSelect from "../../../../../../../UI/Fields/CSelect";
import GroupByField, { IItemOption } from "../../../GroupByField";
import CDateRangePicker from "../../../../../../../UI/Fields/CDateRangePicker";
import FavoriteCheckboxField from "../../../../../../../UI/Fields/FavoriteCheckboxField";
import MuiAutocompleteSelectLazy from "../../../../../../../UI/Fields/MuiAutocompleteSelectLazy";
import { ISummaryReportFilterDataType as IFilterData } from "../../../../types";
import MuiAutocompleteSelect from "../../../../../../../UI/Fields/MuiAutocompleteSelect";
import MuiTwiceDateRangePicker from "../../../../../../../UI/Fields/MuiTwiceDateRangePicker";
import SaveFilterForm from "../../../../../../../common/FiltersComponents/SaveFilterForm";
import { initialSummaryReportFilterData } from "../../../DesktopReport";
import MuiChipsInputField from "../../../../../../../UI/Fields/MuiChipsInput";


interface IFilterProps {
  initialFilter: IFilterData;
  activeField: string;
  setUpdateFilter: (data: any) => void;
  setToggleDrawer: (data: boolean) => void;
  setGenerateReport: React.Dispatch<React.SetStateAction<boolean>>;
  editMode?: boolean;
  filterName?: string;
  onSave?: (data: object) => void;
  setSelectedFilterId?: React.Dispatch<React.SetStateAction<string>>;
  apiV2: boolean;
}

const Filter: React.FC<IFilterProps> = ({
  initialFilter,
  activeField,
  setUpdateFilter,
  setToggleDrawer,
  setGenerateReport,
  editMode = false,
  filterName = '',
  onSave,
  setSelectedFilterId,
  apiV2 = false,
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const mobile = useMediaQuery('(max-width:767px)');
  const { hasPermissions } = usePermissions();

  const hasMyFilters = hasPermissions(["api3.admin.playercommissionreportcontroller.my_filters"]);

  const context = useContext(GlobalContext);

  const {
    getProducts,
    getManagers,
    getAffiliates,
    getTrackers,
    getCreatives,
    getBrands,
    getCurrencies,
    getDeviceTypes,
  } = useSummaryReport();
  const { countries } = useRegistration();

  const optionsData = useSelector(selectOptionsData);
  const dealsData = useSelector(selectDealsData);
  const platformsData = useSelector(selectPlatformsData);

  //state
  const [filter, setFilter] = useState<IFilterData>(editMode ? initialFilter : context.admSummaryReportFilter);
  const [fieldName, setFieldName] = useState<string>(filterName);

  const [dataCountries, setDataCountries] = useState<any | null>(null);

  const [selectedPeriod, setSelectedPeriod] = useState<PeriodTypes>(initialFilter.period);
  const [startDate, setStartDate] = useState<Date | ''>(new Date(initialFilter.sent_from));
  const [endDate, setEndDate] = useState<Date | ''>(new Date(initialFilter.sent_to));

  const [registrationFrom, setRegistrationFrom] = useState<Date | ''>(!!initialFilter.registrationFrom ? new Date(initialFilter.registrationFrom) : '');
  const [registrationTo, setRegistrationTo] = useState<Date | ''>(!!initialFilter.registrationTo ? new Date(initialFilter.registrationTo) : '');

  const [firstDepositFrom, setFirstDepositFrom] = useState<Date | ''>(!!initialFilter.firstDepositFrom ? new Date(initialFilter.firstDepositFrom) : '');
  const [firstDepositTo, setFirstDepositTo] = useState<Date | ''>(!!initialFilter.firstDepositTo ? new Date(initialFilter.firstDepositTo) : '');

  const [cpaQualificationFrom, setCpaQualificationFrom] = useState<Date | ''>(!!initialFilter.cpaQualificationFrom ? new Date(initialFilter.cpaQualificationFrom) : '');
  const [cpaQualificationTo, setCpaQualificationTo] = useState<Date | ''>(!!initialFilter.cpaQualificationTo ? new Date(initialFilter.cpaQualificationTo) : '');

  const [groupBy, setGroupBy] = useState<IItemOption[]>(editMode ? initialFilter.group_by.fields : context.admSummaryReportFilter.group_by.fields);

  //favorite fields state
  const [favAdmSRProductIds, setFavAdmSRProductIds] = useLocalStorage<boolean>(favoriteFilterKeys.productIds, false);
  const [favAdmSRPlatformIds, setFavAdmSRPlatformIds] = useLocalStorage<boolean>(favoriteFilterKeys.platformIds, false);
  const [favAdmSRManagerIds, setFavAdmSRManagerIds] = useLocalStorage<boolean>(favoriteFilterKeys.managerIds, false);
  const [favAdmSRAffiliateIds, setFavAdmSRAffiliateIds] = useLocalStorage<boolean>(favoriteFilterKeys.affiliateIds, false);
  const [favAdmSRTrackerIds, setFavAdmSRTrackerIds] = useLocalStorage<boolean>(favoriteFilterKeys.trackerIds, false);
  const [favAdmSRCreativeIds, setFavAdmSRCreativeIds] = useLocalStorage<boolean>(favoriteFilterKeys.creativeIds, false);
  const [favAdmSRBrands, setFavAdmSRBrands] = useLocalStorage<boolean>(favoriteFilterKeys.brands, false);
  const [favAdmSRCurrencies, setFavAdmSRCurrencies] = useLocalStorage<boolean>(favoriteFilterKeys.currencies, false);
  const [favAdmSRDealTypes, setFavAdmSRDealTypes] = useLocalStorage<boolean>(favoriteFilterKeys.dealTypes, false);
  const [favAdmSRAnid, setFavAdmSRAnid] = useLocalStorage<boolean>(favoriteFilterKeys.anid, false);
  const [favAdmSRPlayerId, setFavAdmSRPlayerId] = useLocalStorage<boolean>(favoriteFilterKeys.playerId, false);
  const [favAdmSRSource1, setFavAdmSRSource1] = useLocalStorage<boolean>(favoriteFilterKeys.source1, false);
  const [favAdmSRSource2, setFavAdmSRSource2] = useLocalStorage<boolean>(favoriteFilterKeys.source2, false);
  const [favAdmSRSource3, setFavAdmSRSource3] = useLocalStorage<boolean>(favoriteFilterKeys.source3, false);
  const [favAdmSRSource4, setFavAdmSRSource4] = useLocalStorage<boolean>(favoriteFilterKeys.source4, false);
  const [favAdmSRSource5, setFavAdmSRSource5] = useLocalStorage<boolean>(favoriteFilterKeys.source5, false);
  const [favAdmSRDeviceTypes, setFavAdmSRDeviceTypes] = useLocalStorage<boolean>(favoriteFilterKeys.deviceTypes, false);
  const [favAdmSRGeo, setFavAdmSRGeo] = useLocalStorage<boolean>(favoriteFilterKeys.geo, false);

  useEffect(() => {
    if (!!selectedPeriod) {
      const period = getPeriod(selectedPeriod);

      if (!!period.start && !!period.end) {
        setStartDate(period.start);
        setEndDate(period.end);
        setFilter((prevState: any) => {
          return {
            ...prevState,
            period: selectedPeriod,
            sent_from: format(startOfDay(new Date(period.start)), "yyyy-MM-dd HH:mm:ss"),
            sent_to: format(endOfDay(new Date(period.end)), "yyyy-MM-dd HH:mm:ss"),
          }
        })
      }
    }
  }, [selectedPeriod]);

  useEffect(() => {
    setSelectedPeriod(filter.period);
  }, [filter.period]);

  useEffect(() => {
    if (!editMode) {
      context.setAdmSummaryReportFilter(filter);
    }
  }, [filter]);

  useEffect(() => {
    setFilter((prevState) => {
      return {
        ...prevState,
        group_by: {
          fields: groupBy,
        },
      }
    })
  }, [groupBy]);

  useEffect(() => {
    if (!!countries.data) {
      const data = countries.data.reduce((acc: any[], item: any) => {
        acc.push({
          id: item.code,
          value: item.code,
        });
        return acc;
      }, []);
      setDataCountries({
        list: data,
        total_entries: data.length,
      });
    }
  }, [countries, i18n.language]);

  const handleChangeDates = (start: Date, end: Date) => {
    const noEndDate = !end;

    setStartDate(start);
    setEndDate(noEndDate ? start : end);
    setFilter((prevState: any) => {
      return {
        ...prevState,
        period: '',
        sent_from: format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss"),
        sent_to: format(endOfDay(new Date(noEndDate ? start : end)), "yyyy-MM-dd HH:mm:ss"),
      }
    });
  };

  const handleChangePeriodSelect = (event: SelectChangeEvent) => {
    //@ts-ignore
    const value: PeriodTypes = event.target.value;
    setSelectedPeriod(value);
  };

  const handleApplyFilter = useCallback(() => {
    setUpdateFilter(filter);
    setToggleDrawer(false);

    if (!editMode) {
      if (typeof setSelectedFilterId === 'function') {
        setSelectedFilterId('');
      }
      setTimeout(() => {
        setGenerateReport((prevState) => {
          return !prevState
        });
      }, 120);
    }
  }, [filter]);

  const fieldPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const fieldTrackerIdsPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
      affiliateIds: !!filter.affiliateIds.length ? getDataIds(filter.affiliateIds) : null,
    }
  };

  const fieldAffiliateIdsPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
      managerIds: !!filter.managerIds.length ? getDataIds(filter.managerIds) : null,
    }
  };

  const fieldCurrenciesPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
      brandCodes: !!filter.brands.length ? getDataIds(filter.brands) : null,
    }
  };

  const handleChangeRegistrationDate = (start: Date | null, end: Date | null) => {
    setRegistrationFrom(!!start ? start : '');
    setRegistrationTo(!!end ? end : '');

    setFilter((prevState: any) => {
      return {
        ...prevState,
        registrationFrom: !!start ? format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss") : '',
        registrationTo: !!end ? format(endOfDay(new Date(end)), "yyyy-MM-dd HH:mm:ss") : '',
      }
    })
  }

  const handleChangeFirstDepositDate = (start: Date | null, end: Date | null) => {
    setFirstDepositFrom(!!start ? start : '');
    setFirstDepositTo(!!end ? end : '');

    setFilter((prevState: any) => {
      return {
        ...prevState,
        firstDepositFrom: !!start ? format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss") : '',
        firstDepositTo: !!end ? format(endOfDay(new Date(end)), "yyyy-MM-dd HH:mm:ss") : '',
      }
    })
  }

  const handleChangeCpaQualificationDate = (start: Date | null, end: Date | null) => {
    setCpaQualificationFrom(!!start ? start : '');
    setCpaQualificationTo(!!end ? end : '');

    setFilter((prevState: any) => {
      return {
        ...prevState,
        cpaQualificationFrom: !!start ? format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss") : '',
        cpaQualificationTo: !!end ? format(endOfDay(new Date(end)), "yyyy-MM-dd HH:mm:ss") : '',
      }
    })
  }

  const validateName = validationTextFieldMax(fieldName, 255, {
    required: t("common.validation.required_field"),
    maxLength: t("common.validation.max_length"),
  });

  const handleSave = useCallback(() => {
    if (typeof onSave === 'function') {
      onSave({
        name: fieldName,
        filter: filter,
      })
    }
  }, [filter, fieldName]);

  return (<>
    {!!dataCountries && !!optionsData && (<>
      <Box className={styles.wrapper}>
        <Scrollbars
          className={styles.customScroll}
          style={{ overflow: "hidden" }}
          hideTracksWhenNotNeeded={true}
          renderView={props => <div {...props} className="view"/>}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
          renderTrackVertical={props => <div {...props} className="track-vertical"/>}
        >
          {editMode && (
            <Box className={styles.box}>
              <TextField
                className={styles.fieldFull}
                size="small"
                id="filter_name"
                name="filter_name"
                type="text"
                label={t(`common.forms.fields.filter_name`)}
                value={fieldName}
                error={!!validateName}
                helperText={!!validateName ? validateName : ''}
                onChange={(event) => {
                  setFieldName(event.target.value);
                }}
              />
            </Box>
          )}
          {(<>
            {mobile && (<>
              <Box className={styles.box}>
                <CSelect
                  name="period"
                  label={t("admin.reports.filter.period")}
                  value={selectedPeriod}
                  options={summaryPeriodOptions}
                  onChange={handleChangePeriodSelect}
                  prefix="common.components.periods."
                />
              </Box>

              <Box className={`${styles.box} noMargin`}>
                <CDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleChangeDates}
                />
              </Box>

              <Box className={`${styles.box} noMargin`}>
                <GroupByField
                  name="groupBy"
                  value={groupBy}
                  options={getGroupByOptions(optionsData.group_by)}
                  setFilter={setGroupBy}
                  prefix={"admin.reports.filter.group_by_select."}
                />
              </Box>
            </>)}
            {/*ProductIds*/}
            {optionsData.available_filters.includes("productIds") && (
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRProductIds}
                        setChecked={setFavAdmSRProductIds}
                        fieldName={t(`admin.reports.filter.productIds`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiAutocompleteSelectLazy
                      keyValue="value"
                      keyId="id"
                      id="productIds"
                      name="productIds"
                      label={t(`admin.reports.filter.productIds`)}
                      value={filter.productIds}
                      multiple={true}
                      showSelectAll={false}
                      disabled={false}
                      getOptions={getProducts}
                      payloadData={fieldPayload()}
                      labelWithId={false}
                      onChange={setFilter}
                      disableCloseOnSelect={true}
                      autoFocus={activeField === 'productIds'}
                      showLabelCounter={true}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {/*PlatformIds*/}
            {optionsData.available_filters.includes("platformIds") && (<>
              {!!platformsData && platformsData.list.length > 1 && (
                <Box className={styles.box}>
                  <Box className={styles.fieldWrapper}>
                    {!editMode && !mobile && (
                      <Box className={styles.fieldFavorite}>
                        <FavoriteCheckboxField
                          checked={favAdmSRPlatformIds}
                          setChecked={setFavAdmSRPlatformIds}
                          fieldName={t(`admin.reports.filter.platformIds`)}
                        />
                      </Box>
                    )}
                    <Box className={styles.field}>
                      <MuiAutocompleteSelect
                        keyValue="value"
                        keyId="id"
                        id="platformIds"
                        name="platformIds"
                        label={t(`admin.reports.filter.platformIds`)}
                        value={filter.platformIds}
                        multiple={true}
                        showSelectAll={false}
                        disabled={false}
                        staticOptions={platformsData.list}
                        onChange={setFilter}
                        disableCloseOnSelect={true}
                        autoFocus={activeField === 'platformIds'}
                        showLabelCounter={true}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </>)}
            {/*ManagerIds*/}
            {optionsData.available_filters.includes("managerIds") && (
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRManagerIds}
                        setChecked={setFavAdmSRManagerIds}
                        fieldName={t(`admin.reports.filter.managerIds`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiAutocompleteSelectLazy
                      keyValue="value"
                      keyId="id"
                      id="managerIds"
                      name="managerIds"
                      label={t(`admin.reports.filter.managerIds`)}
                      value={filter.managerIds}
                      multiple={true}
                      showSelectAll={false}
                      disabled={false}
                      getOptions={getManagers}
                      payloadData={fieldPayload()}
                      labelWithId={true}
                      onChange={setFilter}
                      disableCloseOnSelect={true}
                      autoFocus={activeField === 'managerIds'}
                      showLabelCounter={true}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {/*AffiliateIds*/}
            {optionsData.available_filters.includes("affiliateIds") && (
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRAffiliateIds}
                        setChecked={setFavAdmSRAffiliateIds}
                        fieldName={t(`admin.reports.filter.aff_id`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiAutocompleteSelectLazy
                      keyValue="value"
                      keyId="id"
                      id="affiliateIds"
                      name="affiliateIds"
                      label={t(`admin.reports.filter.aff_id`)}
                      value={filter.affiliateIds}
                      multiple={true}
                      showSelectAll={false}
                      disabled={false}
                      getOptions={getAffiliates}
                      payloadData={fieldAffiliateIdsPayload()}
                      labelWithId={true}
                      onChange={setFilter}
                      disableCloseOnSelect={true}
                      autoFocus={activeField === 'affiliateIds'}
                      showLabelCounter={true}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {/*TrackerIds*/}
            {optionsData.available_filters.includes("trackerIds") && (
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRTrackerIds}
                        setChecked={setFavAdmSRTrackerIds}
                        fieldName={t(`admin.reports.filter.tracker_id`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiAutocompleteSelectLazy
                      keyValue="value"
                      keyId="id"
                      id="trackerIds"
                      name="trackerIds"
                      label={t(`admin.reports.filter.tracker_id`)}
                      value={filter.trackerIds}
                      multiple={true}
                      showSelectAll={false}
                      disabled={false}
                      getOptions={getTrackers}
                      payloadData={fieldTrackerIdsPayload()}
                      labelWithId={true}
                      onChange={setFilter}
                      disableCloseOnSelect={true}
                      autoFocus={activeField === 'trackerIds'}
                      showLabelCounter={true}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {/*CreativeIds*/}
            {optionsData.available_filters.includes("creativeIds") && (
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRCreativeIds}
                        setChecked={setFavAdmSRCreativeIds}
                        fieldName={t(`admin.reports.filter.creative_id`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiAutocompleteSelectLazy
                      keyValue="value"
                      keyId="id"
                      id="creativeIds"
                      name="creativeIds"
                      label={t(`admin.reports.filter.creative_id`)}
                      value={filter.creativeIds}
                      multiple={true}
                      showSelectAll={false}
                      disabled={false}
                      getOptions={getCreatives}
                      payloadData={fieldPayload()}
                      labelWithId={true}
                      onChange={setFilter}
                      disableCloseOnSelect={true}
                      autoFocus={activeField === 'creativeIds'}
                      showLabelCounter={true}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {/*GEO*/}
            {optionsData.available_filters.includes("geo") && apiV2 && (<>
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRGeo}
                        setChecked={setFavAdmSRGeo}
                        fieldName={t(`admin.reports.filter.geo`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiAutocompleteSelect
                      keyValue="value"
                      keyId="id"
                      id="geo"
                      name="geo"
                      label={t(`admin.reports.filter.geo`)}
                      value={filter.geo}
                      multiple={true}
                      showSelectAll={false}
                      disabled={false}
                      staticOptions={dataCountries.list}
                      onChange={setFilter}
                      disableCloseOnSelect={true}
                      autoFocus={activeField === 'geo'}
                      showLabelCounter={true}
                      prefix={`common.components.creative.list_geolocales.`}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {/*Brands*/}
            {optionsData.available_filters.includes("brands") && (
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRBrands}
                        setChecked={setFavAdmSRBrands}
                        fieldName={t(`admin.reports.filter.brands`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiAutocompleteSelectLazy
                      keyValue="value"
                      keyId="id"
                      id="brands"
                      name="brands"
                      label={t(`admin.reports.filter.brands`)}
                      value={filter.brands}
                      multiple={true}
                      showSelectAll={false}
                      disabled={false}
                      getOptions={getBrands}
                      payloadData={fieldPayload()}
                      labelWithId={true}
                      onChange={setFilter}
                      disableCloseOnSelect={true}
                      autoFocus={activeField === 'brands'}
                      showLabelCounter={true}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {/*Currency*/}
            {optionsData.available_filters.includes("currencies") && (
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRCurrencies}
                        setChecked={setFavAdmSRCurrencies}
                        fieldName={t(`admin.reports.filter.currencies`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiAutocompleteSelectLazy
                      keyValue="value"
                      keyId="id"
                      id="currencies"
                      name="currencies"
                      label={t(`admin.reports.filter.currencies`)}
                      value={filter.currencies}
                      multiple={true}
                      showSelectAll={false}
                      disabled={false}
                      getOptions={getCurrencies}
                      payloadData={fieldCurrenciesPayload()}
                      labelWithId={false}
                      onChange={setFilter}
                      disableCloseOnSelect={true}
                      autoFocus={activeField === 'currencies'}
                      showLabelCounter={true}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {/*DealTypes*/}
            {optionsData.available_filters.includes("dealTypes") && (<>
              {!!dealsData && dealsData.list.length > 0 && (
                <Box className={styles.box}>
                  <Box className={styles.fieldWrapper}>
                    {!editMode && !mobile && (
                      <Box className={styles.fieldFavorite}>
                        <FavoriteCheckboxField
                          checked={favAdmSRDealTypes}
                          setChecked={setFavAdmSRDealTypes}
                          fieldName={t(`admin.reports.filter.deal_type`)}
                        />
                      </Box>
                    )}
                    <Box className={styles.field}>
                      <MuiAutocompleteSelect
                        keyValue="value"
                        keyId="id"
                        id="dealTypes"
                        name="dealTypes"
                        label={t(`admin.reports.filter.deal_type`)}
                        value={filter.dealTypes}
                        multiple={true}
                        showSelectAll={false}
                        disabled={false}
                        staticOptions={dealsData.list}
                        onChange={setFilter}
                        disableCloseOnSelect={true}
                        autoFocus={activeField === 'dealTypes'}
                        showLabelCounter={true}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </>)}
            {/*RegistrationDate*/}
            {optionsData.available_filters.includes("registrationPeriod") && (<>
              <Box className={styles.box}>
                <Box className={`${styles.fieldWrapper} ${!editMode ? styles.fieldWrapperPadding : ''}`}>
                  <Box className={styles.field}>
                    <MuiTwiceDateRangePicker
                      label={'admin.reports.filter.registrationDate'}
                      startLabel={'admin.reports.filter.from'}
                      endLabel={'admin.reports.filter.to'}
                      startDate={registrationFrom}
                      endDate={registrationTo}
                      onChange={handleChangeRegistrationDate}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}
            {/*FirstDeposit*/}
            {optionsData.available_filters.includes("firstDepositPeriod") && (<>
              <Box className={styles.box}>
                <Box className={`${styles.fieldWrapper} ${!editMode ? styles.fieldWrapperPadding : ''}`}>
                  <Box className={styles.field}>
                    <MuiTwiceDateRangePicker
                      label={'admin.reports.filter.firstDepositDate'}
                      startLabel={'admin.reports.filter.from'}
                      endLabel={'admin.reports.filter.to'}
                      startDate={firstDepositFrom}
                      endDate={firstDepositTo}
                      onChange={handleChangeFirstDepositDate}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {/*cpaQualificationPeriod*/}
            {optionsData.available_filters.includes("cpaQualificationPeriod") && apiV2 && (<>
              <Box className={styles.box}>
                <Box className={`${styles.fieldWrapper} ${!editMode ? styles.fieldWrapperPadding : ''}`}>
                  <Box className={styles.field}>
                    <MuiTwiceDateRangePicker
                      label={'admin.reports.filter.cpaQualificationPeriod'}
                      startLabel={'admin.reports.filter.from'}
                      endLabel={'admin.reports.filter.to'}
                      startDate={cpaQualificationFrom}
                      endDate={cpaQualificationTo}
                      onChange={handleChangeCpaQualificationDate}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {optionsData.available_filters.includes("anid") && (<>
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRAnid}
                        setChecked={setFavAdmSRAnid}
                        fieldName={t(`admin.reports.filter.anid`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiChipsInputField
                      id="anid"
                      name="anid"
                      label={t(`admin.reports.filter.anid`)}
                      value={filter.anid}
                      onChange={setFilter}
                      autoFocus={activeField === 'anid'}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {optionsData.available_filters.includes("playerId") && (<>
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRPlayerId}
                        setChecked={setFavAdmSRPlayerId}
                        fieldName={t(`admin.reports.filter.playerId`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiChipsInputField
                      id="playerId"
                      name="playerId"
                      label={t(`admin.reports.filter.playerId`)}
                      value={filter.playerId}
                      onChange={setFilter}
                      autoFocus={activeField === 'playerId'}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {optionsData.available_filters.includes("source1") && (<>
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRSource1}
                        setChecked={setFavAdmSRSource1}
                        fieldName={t(`admin.reports.filter.source1`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiChipsInputField
                      id="source1"
                      name="source1"
                      label={t(`admin.reports.filter.source1`)}
                      value={filter.source1}
                      onChange={setFilter}
                      autoFocus={activeField === 'source1'}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {optionsData.available_filters.includes("source2") && (<>
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRSource2}
                        setChecked={setFavAdmSRSource2}
                        fieldName={t(`admin.reports.filter.source2`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiChipsInputField
                      id="source2"
                      name="source2"
                      label={t(`admin.reports.filter.source2`)}
                      value={filter.source2}
                      onChange={setFilter}
                      autoFocus={activeField === 'source2'}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {optionsData.available_filters.includes("source3") && (<>
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRSource3}
                        setChecked={setFavAdmSRSource3}
                        fieldName={t(`admin.reports.filter.source3`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiChipsInputField
                      id="source3"
                      name="source3"
                      label={t(`admin.reports.filter.source3`)}
                      value={filter.source3}
                      onChange={setFilter}
                      autoFocus={activeField === 'source3'}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {optionsData.available_filters.includes("source4") && (<>
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRSource4}
                        setChecked={setFavAdmSRSource4}
                        fieldName={t(`admin.reports.filter.source4`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiChipsInputField
                      id="source4"
                      name="source4"
                      label={t(`admin.reports.filter.source4`)}
                      value={filter.source4}
                      onChange={setFilter}
                      autoFocus={activeField === 'source4'}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {optionsData.available_filters.includes("source5") && (<>
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRSource5}
                        setChecked={setFavAdmSRSource5}
                        fieldName={t(`admin.reports.filter.source5`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiChipsInputField
                      id="source5"
                      name="source5"
                      label={t(`admin.reports.filter.source5`)}
                      value={filter.source5}
                      onChange={setFilter}
                      autoFocus={activeField === 'source5'}
                    />
                  </Box>
                </Box>
              </Box>
            </>)}

            {optionsData.available_filters.includes("deviceTypes") && (
              <Box className={styles.box}>
                <Box className={styles.fieldWrapper}>
                  {!editMode && !mobile && (
                    <Box className={styles.fieldFavorite}>
                      <FavoriteCheckboxField
                        checked={favAdmSRDeviceTypes}
                        setChecked={setFavAdmSRDeviceTypes}
                        fieldName={t(`admin.reports.filter.deviceTypes`)}
                      />
                    </Box>
                  )}
                  <Box className={styles.field}>
                    <MuiAutocompleteSelectLazy
                      keyValue="value"
                      keyId="id"
                      id="deviceTypes"
                      name="deviceTypes"
                      label={t(`admin.reports.filter.deviceTypes`)}
                      value={filter.deviceTypes}
                      multiple={true}
                      showSelectAll={false}
                      disabled={false}
                      getOptions={getDeviceTypes}
                      payloadData={fieldPayload()}
                      labelWithId={false}
                      onChange={setFilter}
                      disableCloseOnSelect={true}
                      autoFocus={activeField === 'deviceTypes'}
                      showLabelCounter={true}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {!editMode && hasMyFilters && (
              <SaveFilterForm
                filter={{
                  ...filter,
                  geo: !!filter.geo?.length ? filter.geo.map((item) => {
                    return {
                      id: item.id,
                      value: item.id,
                    }
                  }) : []
                }}
                type={reportStorageProps.type}
                target={reportStorageProps.target}
                exceptions={reportStorageProps.exceptions}
                exceptionsIds={reportStorageProps.exceptionsIds}
                disabled={
                  arePropsEqual(
                    {
                      ...initialSummaryReportFilterData,
                      sent_from: format(new Date(initialSummaryReportFilterData.sent_from), "yyyy-MM-dd HH:mm:ss"),
                      sent_to: format(new Date(initialSummaryReportFilterData.sent_to), "yyyy-MM-dd HH:mm:ss"),
                    },
                    {
                      ...filter,
                      sent_from: format(new Date(filter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                      sent_to: format(new Date(filter.sent_to), "yyyy-MM-dd HH:mm:ss"),
                    }
                  )
                }
              />
            )}
          </>)}
        </Scrollbars>
      </Box>
      <Box className={styles.actions}>
        <Box className={styles.actionsWrapper}>
          {editMode ? (
            <Button
              gradient
              className={styles.button}
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              onClick={handleSave}
              disabled={
                arePropsEqual({
                  name: filterName,
                  ...initialFilter,
                  sent_from: format(new Date(initialFilter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                  sent_to: format(new Date(initialFilter.sent_to), "yyyy-MM-dd HH:mm:ss"),
                }, {
                  name: fieldName,
                  ...filter,
                  sent_from: format(new Date(filter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                  sent_to: format(new Date(filter.sent_to), "yyyy-MM-dd HH:mm:ss"),
                }) || !!validateName
              }
            >
              {t("common.buttons.save")}
            </Button>
          ) : (
            <Button
              gradient
              className={styles.button}
              fullWidth
              disableElevation
              type="submit"
              variant="contained"
              onClick={handleApplyFilter}
              disabled={arePropsEqual(initialFilter, filter)}
            >
              {t("common.buttons.apply")}
            </Button>
          )}
          <Button
            className={styles.button}
            color="secondary"
            disableElevation
            type="button"
            variant="outlined"
            onClick={() => setToggleDrawer(false)}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </Box>
    </>)}
  </>);
};

export default Filter;
