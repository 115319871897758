//core
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { useLocalStorage } from "usehooks-ts";
import { useSelector } from "react-redux";
import { differenceInDays, endOfDay, startOfDay } from "date-fns";
import {
  Box,
  SelectChangeEvent, useMediaQuery,
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//helpers
import { getDataIds, getGroupByOptions, getPeriod, PeriodTypes } from "../../../../../utils";

//constant
import { summaryPeriodOptions } from "../../../../../constant";
import { favoriteFilterKeys } from "../../../../constants";

//hooks
import { useCreativeReport } from "../../../../../../../../store/admin/reports/creative_report/useCreativeReport";
import { useRegistration } from "../../../../../../../../store/common/registration/useRegistration";

//selectors
import { selectOptionsData } from "../../../../../../../../store/admin/reports/creative_report/selectors";

//components
import CSelect from "../../../../../../../UI/Fields/CSelect";
import { IFilterData } from "../../../../types";
import { GlobalContext } from "../../../../../../../../context/GlobalContext";
import GroupByField, { IItemOption } from "../../../GroupByField";
import MuiAutocompleteSelectLazy from "../../../../../../../UI/Fields/MuiAutocompleteSelectLazy";
import MuiAutocompleteSelect from "../../../../../../../UI/Fields/MuiAutocompleteSelect";
import CDateRangePicker from "../../../../../../../UI/Fields/CDateRangePicker";


interface IVisibleFilterProps {
  filter: IFilterData;
  setUpdateFilter: Dispatch<SetStateAction<IFilterData>>;
}

const VisibleFilter: React.FC<IVisibleFilterProps> = ({
  filter,
  setUpdateFilter,
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const mobile = useMediaQuery('(max-width:767px)');

  const {
    getAffiliates,
    getTrackers,
    getDeals,
    getCreatives,
  } = useCreativeReport();
  const { countries } = useRegistration();

  const context = useContext(GlobalContext);

  const optionsData = useSelector(selectOptionsData);

  //state
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodTypes>(filter.period);
  const [startDate, setStartDate] = useState<Date | ''>(getPeriod(filter.period).start);
  const [endDate, setEndDate] = useState<Date | ''>(getPeriod(filter.period).end);
  const [groupBy, setGroupBy] = useState<IItemOption[]>(filter.group_by.fields);
  const [dataCountries, setDataCountries] = useState<any | null>(null);

  //favorite fields state
  const [favCRAffiliateIds, setFavCRAffiliateIds] = useLocalStorage<boolean>(favoriteFilterKeys.affiliateIds, false);
  const [favCRDealTypes, setFavCRDealTypes] = useLocalStorage<boolean>(favoriteFilterKeys.dealTypes, false);
  const [favCRTrackerIds, setFavCRTrackerIds] = useLocalStorage<boolean>(favoriteFilterKeys.trackerIds, false);
  const [favCRCreativeIds, setFavCRCreativeIds] = useLocalStorage<boolean>(favoriteFilterKeys.creativeIds, false);
  const [favCRGeo, setFavCRGeo] = useLocalStorage<boolean>(favoriteFilterKeys.geo, false);

  useEffect(() => {
    if (!!selectedPeriod) {
      const period = getPeriod(selectedPeriod);

      if (!!period.start && !!period.end) {
        setStartDate(period.start);
        setEndDate(period.end);

        context.setAdmCreativesReportFilter({
          ...context.admCreativesReportFilter,
          period: selectedPeriod,
          sent_from: format(startOfDay(new Date(period.start)), "yyyy-MM-dd HH:mm:ss"),
          sent_to: format(endOfDay(new Date(period.end)), "yyyy-MM-dd HH:mm:ss"),
        });
        setUpdateFilter((prevState: any) => {
          return {
            ...prevState,
            period: selectedPeriod,
            sent_from: format(startOfDay(new Date(period.start)), "yyyy-MM-dd HH:mm:ss"),
            sent_to: format(endOfDay(new Date(period.end)), "yyyy-MM-dd HH:mm:ss"),
          }
        })
      }
    }
  }, [selectedPeriod]);

  useEffect(() => {
    setSelectedPeriod(filter.period);
  }, [filter.period]);

  useEffect(() => {
    setGroupBy(filter.group_by.fields);
  }, [filter.group_by.fields]);

  useEffect(() => {
    setUpdateFilter((prevState) => {
      return {
        ...prevState,
        group_by: {
          fields: groupBy,
        },
      }
    })
  }, [groupBy]);

  useEffect(() => {
    if (!!countries.data) {
      const data = countries.data.reduce((acc: any[], item: any) => {
        acc.push({
          id: item.code,
          value: item.code,
        });
        return acc;
      }, []);
      setDataCountries({
        list: data,
        total_entries: data.length,
      });
    }
  }, [countries, i18n.language]);

  useEffect(() => {
    context.setAdmCreativesReportFilter(filter);
  }, [filter]);

  const handleChangeDates = (start: Date, end: Date) => {
    const noEndDate = !end;

    setStartDate(start);
    setEndDate(noEndDate ? start : end);
    context.setAdmCreativesReportFilter({
      ...context.admCreativesReportFilter,
      period: '',
      sent_from: format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss"),
      sent_to: format(endOfDay(new Date(noEndDate ? start : end)), "yyyy-MM-dd HH:mm:ss"),
    });
    setUpdateFilter((prevState: any) => {
      return {
        ...prevState,
        period: '',
        sent_from: format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss"),
        sent_to: format(endOfDay(new Date(noEndDate ? start : end)), "yyyy-MM-dd HH:mm:ss"),
      }
    });
  };

  const handleChangePeriodSelect = (event: SelectChangeEvent) => {
    //@ts-ignore
    const value: PeriodTypes = event.target.value;
    setSelectedPeriod(value);
  };

  const fieldPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const fieldTrackerIdsPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
      affiliateIds: !!filter.affiliateIds.length ? getDataIds(filter.affiliateIds) : null,
    }
  };


  const favoriteFields = !mobile && (favCRAffiliateIds || favCRDealTypes || favCRTrackerIds || favCRCreativeIds || favCRGeo) ? (
    <Box className={styles.favoriteFields}>
      {favCRAffiliateIds && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="value"
            keyId="id"
            id="affiliateIds"
            name="affiliateIds"
            label={t(`admin.reports.filter.aff_id`)}
            value={filter.affiliateIds}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getAffiliates}
            payloadData={fieldPayload()}
            labelWithId={false}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
          />
        </Box>
      )}
      {favCRDealTypes && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="value"
            keyId="id"
            id="dealTypes"
            name="dealTypes"
            label={t(`admin.reports.filter.deal_type`)}
            value={filter.dealTypes}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getDeals}
            payloadData={fieldPayload()}
            labelWithId={false}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
          />
        </Box>
      )}
      {favCRTrackerIds && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="value"
            keyId="id"
            id="trackerIds"
            name="trackerIds"
            label={t(`admin.reports.filter.tracker_id`)}
            value={filter.trackerIds}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getTrackers}
            payloadData={fieldTrackerIdsPayload()}
            labelWithId={false}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
          />
        </Box>
      )}
      {favCRCreativeIds && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="value"
            keyId="id"
            id="creativeIds"
            name="creativeIds"
            label={t(`admin.reports.filter.creative_id`)}
            value={filter.creativeIds}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getCreatives}
            payloadData={fieldPayload()}
            labelWithId={false}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
          />
        </Box>
      )}
      {favCRGeo && !!dataCountries && (
        <Box>
          <MuiAutocompleteSelect
            keyValue="value"
            keyId="id"
            id="geo"
            name="geo"
            label={t(`admin.reports.filter.geo`)}
            value={filter.geo}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            staticOptions={dataCountries.list}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
            prefix={`common.components.creative.list_geolocales.`}
          />
        </Box>
      )}
    </Box>
  ) : null;

  return (<>
    {!!optionsData && !!dataCountries && (<>
      <Box>
        <Box className={styles.wrapper}>
          <Box className={styles.box}>
            <CSelect
              name="period"
              label={t("admin.reports.filter.period")}
              value={selectedPeriod}
              options={summaryPeriodOptions}
              onChange={handleChangePeriodSelect}
              prefix="common.components.periods."
            />
          </Box>
          <Box className={styles.boxDate}>
            <CDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={handleChangeDates}
            />
          </Box>
        </Box>
        <Box className={styles.field}>
          <GroupByField
            name="groupBy"
            value={filter.group_by.fields}
            options={getGroupByOptions(optionsData.group_by)}
            setFilter={setGroupBy}
            prefix={"admin.reports.filter.group_by_select."}
          />
        </Box>

        {favoriteFields}
      </Box>
    </>)}
  </>);
};

export default VisibleFilter;
